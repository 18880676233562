import localFont from 'next/font/local';

const primary = localFont({
  src: [
    { path: './fonts/OS-Thin-131212.woff2', weight: '300', style: 'normal' },
    { path: './fonts/OS-Regular-131212.woff2', weight: '400', style: 'normal' },
    { path: './fonts/OS-Bold-131212.woff2', weight: '700', style: 'normal' },
  ],
  variable: '--font-primary',
  preload: false,
});

const secondary = localFont({
  src: [{ path: './fonts/kalam-bold.woff2', weight: '700', style: 'normal' }],
  variable: '--font-secondary',
  preload: false,
});

const payback = localFont({
  src: [
    {
      path: './fonts/payback-bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/payback-regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/payback-light.woff2',
      weight: '300',
      style: 'normal',
    },
  ],
  preload: false,
  variable: '--font-payback',
});
export const fonts = {
  primary,
  secondary,
  payback,
};
